<template>
  <div>
    <a-card :border="false">
      <div class="query">
        <a-form-model :labelCol="{span: 7}" :wrapperCol="{span:17}">
          <a-row :gutter="4">
            <a-col :xxl="6" :xl="6" :md="8" :sm="12">
              <a-form-model-item label="要货单号">
                <a-input v-model.trim="queryParam.orderCode" placeholder="要货单号" :max-length="50"></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :xxl="6" :xl="6" :md="8" :sm="12">
              <a-form-model-item label="需求单号">
                <a-input v-model.trim="queryParam.demandCode" placeholder="需求单号" :max-length="50"></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :xxl="6" :xl="6" :md="8" :sm="12">
              <a-form-model-item label="订单状态">
                <DictSelect field="scanOrderStatus" :value.sync="queryParam.orderStatus" style="width: 100%" placeholder="订单状态"></DictSelect>
              </a-form-model-item>
            </a-col>
            <a-col :xxl="6" :xl="6" :md="8" :sm="12">
              <a-form-model-item :labelCol="{span: 0}" :wrapperCol="{span:22,offset:1}">
                <a-button @click="toSearch(0)" type="primary" icon="search">查询</a-button>
                <a-button @click="toSearch(1)" type="default" icon="sync">重置</a-button>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </div>
      <div>
        <a-button v-if="isScanCode" @click="toHandler(null,'ship')" type="default" icon="scan">扫码发货</a-button>
      </div>
      <div class="table">
        <a-table :scroll="{ x: 1300 }" :data-source="tableData" bordered rowKey="id" :pagination="page" @change="p=>{page=p;toSearch(2)}"
          :row-selection="{fixed: true,type: 'radio',selectedRowKeys:selectedRowKeys,onChange:
            (keys,rows)=>{selectedRowKeys=keys;selectedRows= rows}}"
          :customRow="record=>{return{on:{click:e=>{selectedRowKeys[0] ==record.id?selectedRows=[]:selectedRows=[record];selectedRowKeys[0] ==record.id?selectedRowKeys=[]:selectedRowKeys=[record.id]}}}}">
          <a-table-column title="需求单号" data-index="demandCode" :width="210" :ellipsis="true" align="left" fixed="left">
            <template slot-scope="text" fixed='left'>
              <a-tooltip> <template slot="title"> {{text}} </template> {{text}} </a-tooltip>
            </template>
          </a-table-column>
          <a-table-column title="要货单号" data-index="orderCode" :width="210" :ellipsis="true" align="left">
          </a-table-column>
          <a-table-column title="订单状态" data-index="orderStatus" :width="120" :ellipsis="true" align="left">
            <template slot-scope="text, record">
              <a-tag v-if="text==0" color="#e6a23c">待审核</a-tag>
              <a-tag v-if="text==1" color="#409eff">已审核</a-tag>
              <a-tag v-if="text==2" color="#409eff">部分发货</a-tag>
              <a-tag v-if="text==3" color="#ff7875">已发货</a-tag>
              <a-tag v-if="text==4" color="#67c23a">已完成</a-tag>
              <a-tag v-if="text==5" color="#909399">已作废</a-tag>
            </template>
          </a-table-column>
          <a-table-column title="经销商" data-index="customerName" :ellipsis="true" :width="160"  align="left">
          </a-table-column>
          <a-table-column title="总数/已要/已发" data-index="sendGoodsCount" :width="140" :ellipsis="true" align="left">
            <template slot-scope="text, record">{{record.goodsCount}}/{{record.demandGoodsCount}}/{{record.sendGoodsCount}}</template>
          </a-table-column>
          <a-table-column title="地址" data-index="address" :ellipsis="true" align="left">
          </a-table-column>
          <a-table-column title="订单生成方式" data-index="sourceType" :width="100" :ellipsis="true" align="left">
            <template slot-scope="text, record">{{text|sourceType}}</template>
          </a-table-column>
          <a-table-column title="操作时间" data-index="createDate" :width="210" :ellipsis="true" align="left"></a-table-column>
          <!-- <a-table-column title="操作" data-index="action" :width="100" :ellipsis="true" align="left" fixed="right">
            <template slot-scope="text, record">
              <a href="#" class="a_link" @click="toHandler(record,'ship')">扫码发货</a>
            </template>
          </a-table-column> -->
        </a-table>
      </div>
      <a-modal :maskClosable="false" :title="title" style="top: 8px;" width="85%" v-model="visible" :footer="null"
      :confirmLoading="isLoading" @cancel="toCancel">
      <a-form-model ref="form" :model="form" :labelCol="{span: 6}" :wrapperCol="{span:18}">
        <a-row :gutter="4">
          <a-col :md="12">
            <a-form-model-item label="经销商要货单">
              <a-input v-model.trim="info.orderCode" placeholder="联系方式" :max-length="50" disabled></a-input>
            </a-form-model-item>
          </a-col>
<!--          <a-col :md="12">-->
<!--            <a-form-model-item label="期望到货时间">-->
<!--              <DatePicker :startTime.sync="info.expectTime" disabled></DatePicker>-->
<!--            </a-form-model-item>-->
<!--          </a-col>-->
        </a-row>
        <a-row :gutter="4">
          <a-col :md="12">
            <a-form-model-item label="联系方式">
              <a-input v-model.trim="info.mobile" placeholder="联系方式" :max-length="50" disabled></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="12">
            <a-form-model-item label="联系人">
              <a-input v-model.trim="info.contactPerson" placeholder="联系人" :max-length="50" disabled></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="4">
          <a-col :md="12">
            <a-form-model-item label="发票号">
              <a-input v-model.trim="info.taxNum" placeholder="发票号" :max-length="50" disabled></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="12">
            <a-form-model-item label="发票抬头">
              <a-input v-model.trim="info.taxTitle" placeholder="发票抬头" :max-length="50" disabled></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="4">
          <a-col :md="12">
            <a-form-model-item label="经销商区域">
              <a-input v-model.trim="info.customerArea" placeholder="经销商区域" :max-length="500" disabled></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="12">
            <a-form-model-item label="收货地址">
              <a-input v-model.trim="info.address" placeholder="收货地址" :max-length="50" disabled></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-col :md="24">
          <a-form-model-item label="订单备注" :labelCol="{span: 3}" :wrapperCol="{span:21}">
            <a-textarea placeholder="订单备注" v-model.trim="info.orderRemark" disabled></a-textarea>
          </a-form-model-item>
        </a-col>
        <a-row :gutter="4">
          <a-col :md="24">
            <a-form-model-item label="物流信息" prop="deliveryRemark" :rules="[{required:true,message:'请输入物流信息',trigger:'blur'}]"
              :labelCol="{span: 3}" :wrapperCol="{span:21}">
              <a-input v-model.trim="form.deliveryRemark" placeholder="物流信息" :max-length="100"></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <table cellpadding="0" cellspacing="0" width="100%" border="0" class="cn-table" v-if="details.length>0">
          <tr>
            <th width="140">商品名称</th>
            <th width="140">规格</th>
            <th width="140">CNC商号</th>
            <th width="140">总成号</th>
            <th width="100">商品图片</th>
            <th width="80">要货量</th>
            <th width="80">已发货量</th>
            <th width="100">发货量</th>
            <th width="100">即时库存</th>
            <th width="100">总金额</th>
            <th width="100">防串货码</th>
            <!-- <th width="60">操作</th> -->
          </tr>
          <tr v-for="(item,index) in details" :key="index">
            <td>{{item.goodsName}}</td>
            <td>{{item.specStr}}</td>
            <td>{{item.cncCompany}}</td>
            <td>{{item.assemblyNumber}}</td>
            <td>
              <ZoomMedia style="height: 20px;" :value="item.goodsImg"></ZoomMedia>
            </td>
            <td>{{item.demandGoodsNum||0}}</td>
            <td>{{item.sendNum||0}}</td>
            <td>
              <a-input-number v-model="item.shipNum" style="width: 100%;" :min="0" :max="9999999" :step="1"></a-input-number>
            </td>
            <td>{{item.realQuantity}}</td>
            <td>{{(item.discountBeforePrice * item.shipNum).toFixed(2)}}</td>
            <td>{{item.uniqueCharacter}}</td>
            <!-- <td style="text-align: center;">
              <a href="#" @click="details.splice(index,1)">删除</a>
            </td> -->
          </tr>
        </table>
        <div style="text-align: right;margin-top: 14px;">
          <a-button type="default" @click="toCancel">取消</a-button>
          <a-button type="primary" @click="toSubmit">确认</a-button>
        </div>
      </a-form-model>
    </a-modal>
    </a-card>
  </div>
</template>
<script>
import {checkPermission} from '@/utils/permissions'
  const qs = require('qs')
  export default {
    data() {
      return {
        queryParam: {
          confirmStatus:1
        },
        resetQueryParam: null,
        tableData: [],
        loading: false,
        page: {
          current: 1,
          pageSize: 10,
          total: 0
        },
        visible: false,
        form: {},
        isLoading: false,
        title: "操作",
        details: [],
        info: {},
        selectedRowKeys: [],
        selectedRows: [],
        isScanCode:checkPermission('scanCodeShipping:scanCode')
      }
    },
    methods: {
      toSearch(type) {
        if (type == 1) {
          this.resetQueryParam ? this.queryParam = Object.assign({}, this.resetQueryParam) : this.resetQueryParam =
            Object.assign({}, this.queryParam)
        }
        this.queryParam.pageNumber = type == 2 ? this.page.current : this.page.current = 1
        this.queryParam.pageSize = this.page.pageSize
        const postData = Object.assign({}, this.queryParam, this.page, {
          orderStatus: this.queryParam.orderStatus ? [this.queryParam.orderStatus] : [1, 2]
        })
        this.axios.get(`/api/order/system/orderInfo/list?${qs.stringify(postData)}`).then(res => {
          this.tableData = res.body.records
          this.page.total = res.body.total
        }).catch(err => {})
      },

      toCancel() {
        this.$refs.form && this.$refs.form.resetFields()
        this.visible = false
      },

      /**
       * 增、查、删（存在type）
       */
      toHandler(row, name) {
        if(this.selectedRows.length <= 0) {
          return this.$message.warning('请选择一条数据')
        }
        switch (name) {
          case 'ship':
            this.id = this.selectedRowKeys[0]
            this.visible = true
            this.axios.get(`/api/order/system/orderInfo/selectById/${this.selectedRowKeys[0]}`).then(res => {
              if (res.code == 200) {
                let tmp = res.body
                tmp.details.forEach(x => {
                  x.demandGoodsNum = x.demandNum,
                    x.shipNum = x.shipNum || 0
                })
                let temp = tmp.details.filter(x => x.demandGoodsNum > x.sendNum)
                this.details = temp
                this.info = tmp
                this.form = {}
              }
            }).catch(err => {})
            break;
        }
      },
      /**
       * 提交
       */
      toSubmit() {
        let tmp = this.form
        console.log(tmp)
        this.$refs.form.validate(valid => {
          if (valid) {
            tmp.orderShipDetailVos = this.details
            let postData = Object.assign({}, tmp, {
              orderCode: this.info.orderCode
            })
            this.isLoading = true
            this.axios.post(`/api/order/system/orderShipInfo/save`, postData).then(res => {
              if (res.code == 200) {
                this.$message.success(res.message)
                this.toSearch(0)
                this.visible = false
              }
            }).catch(err => {}).finally(() => {
              this.isLoading = false
            })
          }
        })

      },
      getPageKey() {
        let tmp = this.$route.path
        if (tmp.indexOf('demandList') >= 0) {
          this.pageKey = 1
          this.queryParam.sourceType = 1
        }
        this.toSearch(1)
      }

    },
    created() {
      this.getPageKey()
    },

  }
</script>


<style lang="less" scoped>
</style>
